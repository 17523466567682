/* General Styles for Header */
.header-section {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  padding: 20px 0;
  transition: all 0.3s ease-in-out;
  background: transparent;
}

/* Sticky Header */
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--white);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  animation: slideDown 0.3s ease forwards;
  padding: 12px 0;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.header-section .logo {
  font-size: 28px;
  font-weight: 800;
  color: var(--primaryClr);
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

.header-section .logo a {
  color: var(--primaryClr);
  text-decoration: none;
}

.header-section .nav-link {
  font-size: 18px;
  color: var(--white);
  font-weight: 600;
  margin-left: 25px;
  text-transform: capitalize;
  transition: all 0.3s ease;
}

.header-section .nav-link:hover {
  color: var(--white);
  transform: translateY(-3px);
}

.header-section .nav-link.active {
  color: var(--white);
}

/* Mobile Menu Toggle Button */
.toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: var(--primaryClr);
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.toggle-btn i {
  font-size: 28px;
  color: var(--primaryClr);
}

/* Offcanvas Styles */
.offcanvas-header .logo {
  font-size: 24px;
  font-weight: 700;
  color: var(--primaryClr);
}

.offcanvas-body .nav-link {
  font-size: 18px;
  color: var(--textclr);
  padding: 10px 0;
}

.offcanvas-body .nav-link:hover {
  color: var(--primaryClr);
}

/* Media Queries */
@media only screen and (max-width: 991px) {
  .toggle-btn {
    font-size: 24px;
    border: 2px solid var(--primaryClr);
    padding: 5px 10px;
    border-radius: 8px;
  }

  .offcanvas-header {
    border-bottom: 1px solid #ececec;
  }

  .offcanvas-body .nav-link {
    font-size: 20px;
    padding: 20px 0;
  }
}
