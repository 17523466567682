@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import 'bootstrap/dist/css/bootstrap.min.css';

/* Color and Font Variables */
:root {
  --primaryClr: #fe4e37;
  --secondaryClr: #fffaf9;
  --bgClr: #f8f9fd;
  --white: #ffffff;
  --black: #000000;
  --textclr: #4f5e71;
  --lightTextClr: #6c757d;

  --smallText: 14px; /* Slightly increased for better readability */
  --bodyText: 17px; /* Slightly increased for more readability */
  --subTitle: 20px;
  --heading: 30px;

  --fontFamily: "Lato", sans-serif;
  --fontFamily1: "Libre Baskerville", serif;
}

/* Reset and Typography */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: var(--fontFamily);
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.6; /* Improved line-height for readability */
  color: var(--textclr);
  background-color: var(--bgClr); /* Light background */
  font-family: var(--primary-font);
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--fontFamily1); /* Serif for headings */
  color: var(--black); /* Strong black headings */
  margin-bottom: 1rem;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

/* Links */
a {
  color: var(--primaryClr);
  text-decoration: none;
}

a:hover {
  color: var(--primaryClr);
  text-decoration: underline; /* Add underline on hover */
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem; /* Increased padding for better spacing */
  padding-right: 1.5rem;
  max-width: 1200px;
}

p {
  font-size: var(--bodyText);
  color: var(--textclr);
  line-height: 1.8; /* Improved line-height for readability */
  margin-bottom: 1.2rem; /* Add spacing between paragraphs */
}

/* Headings */
.heading-primary {
  font-size: var(--heading);
  font-weight: 900;
  color: var(--primaryClr); /* Using primary color for main headings */
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 1.5rem;
}

.heading-secondary {
  font-size: var(--subTitle);
  color: var(--textclr);
  text-transform: capitalize;
  margin-bottom: 1rem;
}

/* Buttons */
.primaryBtn {
  background-color: var(--primaryClr);
  color: var(--white);
  padding: 12px 20px;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primaryBtn:hover {
  background-color: darken(var(--primaryClr), 10%); /* Darken on hover */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Utility Classes */
.font-bold {
  font-weight: 800 !important;
}

.text-center {
  text-align: center !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mt-8 {
  margin-top: 3rem !important;
}

.mb-8 {
  margin-bottom: 3rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

/* Spacing */
ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Footer */
.footer {
  background-color: var(--black);
  color: var(--white);
  padding: 4rem 1.5rem;
}

.footer a {
  color: var(--white);
  text-decoration: underline;
}

.footer a:hover {
  color: var(--primaryClr);
}

.footer p {
  color: var(--lightTextClr);
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  .container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
