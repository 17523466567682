.tours_section {
  padding: 50px 0px;
  background: var(--secondaryClr);
}


.call_us {
  background: url("../../assets/images/slider/1.jpg") no-repeat;
  padding: 150px 0px;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}

.call_us .title {
  color: var(--primaryClr);
  font-style: italic;
}

.call_us .heading {
  font-size: 50px;
  color: var(--white);
  line-height: 58px;
  text-align: left;
  font-weight: bold;
  padding: 5px 0 20px 0;
}

.call_us p {
  font-size: var(--subTitle);
  color: var(--white);
  line-height: 30px;
}

.call_us .secondary_btn {
  background: none;
  font-size: 18px;
  color: var(--white);
  display: inline-block;
  border: 2px solid var(--white);
  padding: 12px 8px;
  text-decoration: none;
}

.bounce {
  animation: bounce 2s infinite;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #00000054;
  z-index: -1;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media only screen and (max-width: 1199px) {
  .call_us {
    overflow: hidden;
  }

  .call_us .heading {
    font-size: 28px;
    line-height: 38px;
    padding: 5px 0 10px 0;
  }
}

@media only screen and (max-width: 420px) {
  .call_us {
    overflow: hidden;
  }
  .call_us .heading {
    font-size: 22px;
  }
}

/* Gallery Section */
.gallery-section {
  padding: 60px 0;
  background-color: var(--secondaryClr);
}

.gallery-title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: var(--primaryClr);
  margin-bottom: 40px;
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 0 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}

/* Gallery Image */
.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: all 0.3s ease;
}

/* Overlay */
.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1; /* Show overlay on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .gallery-title {
    font-size: 28px;
  }

  .gallery-grid {
    grid-gap: 15px;
  }

  .gallery-overlay {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .gallery-title {
    font-size: 24px;
  }

  .gallery-overlay {
    font-size: 14px;
  }
}

.about_us {
  background-color: #f8f9fd;
  padding: 60px 0;
}

.about_us h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--primaryClr);
}

.about_us p {
  font-size: 16px;
  line-height: 28px;
  color: #4f5e71;
  margin-bottom: 20px;
}

.about_us img {
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.contact_form {
  background-color: #f8f9fd;
  padding: 60px 0;
}

.contact_form h2 {
  font-size: 36px;
  font-weight: 700;
  color: var(--primaryClr);
}

.contact_form p {
  font-size: 16px;
  color: #4f5e71;
}

.contact_form .form-control {
  border-radius: 8px;
  padding: 12px 15px;
  font-size: 16px;
}

.contact_form .btn-primary {
  background-color: var(--primaryClr);
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.contact_form .btn-primary:hover {
  background-color: darken(var(--primaryClr), 10%);
}

/* Add media queries for better responsiveness */
@media only screen and (max-width: 768px) {
  .contact_form h2 {
    font-size: 28px; /* Reduce heading size */
  }

  .contact_form p {
    font-size: 14px; /* Reduce paragraph text size */
  }

  .contact_form .form-control {
    padding: 10px 12px; /* Adjust padding for form controls */
    font-size: 14px; /* Adjust font size */
  }

  .contact_form .btn-primary {
    padding: 8px 20px; /* Adjust button padding */
    font-size: 14px; /* Adjust font size */
  }

  .contact_form .row > .col-md-6 {
    width: 100%; /* Make form fields full-width */
    margin-bottom: 20px; /* Add some spacing between fields */
  }
}

@media only screen and (max-width: 576px) {
  .contact_form h2 {
    font-size: 24px; /* Further reduce heading size */
  }

  .contact_form p {
    font-size: 12px; /* Further reduce paragraph text size */
  }

  .contact_form .form-control {
    padding: 8px 10px; /* Further adjust padding */
    font-size: 12px; /* Further adjust font size */
  }

  .contact_form .btn-primary {
    padding: 6px 18px; /* Further adjust button padding */
    font-size: 12px; /* Further adjust font size */
  }
}
