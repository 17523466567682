/* General Footer Styles */
.footer-section {
  background: #111; /* Darker black background */
  padding: 50px 0;
  color: whitesmoke;
  position: relative;
}

.footer-section h4 {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.footer-section p,
.footer-section a,
.footer-section i {
  color: #b4b4b4;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-section p {
  line-height: 1.8;
}

.footer-section a:hover {
  color: var(--primaryClr);
}

.footer-section .location a {
  color: #fff;
}

.footer-section .location a:hover {
  color: var(--primaryClr);
}

/* Divider Line */
.border-top {
  border-top: 1px solid #444;
}

.copyright {
  font-size: 14px;
  color: #aaa;
}

/* Back to Top Button */
#back-top {
  opacity: 0;
  z-index: 999;
  font-size: 24px;
  color: white;
  line-height: 50px;
  text-align: center;
  position: fixed;
  right: 20px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--primaryClr);
  transition: all 0.4s ease;
}

#back-top.active {
  opacity: 1;
  transform: translateY(-10px);
}

#back-top i {
  transition: transform 0.3s ease;
}

#back-top:hover i {
  transform: scale(1.1);
}
